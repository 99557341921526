import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getFollowupByLead } from "../../store/actions/followup_action";
function FollowUpComponent({ id }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.followup);
  const { lead_followups, loading } = data;
  useEffect(() => {
    dispatch(getFollowupByLead(id));
  }, [id]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Follow Ups </h4>
            </div>
            <div className="card-body">
              <table className="table align-middle  table-striped ">
                <thead>
                  <tr className="bg-transparent" role="row">
                    <th>#</th>
                    <th> Follow Up Date </th>
                    <th> Follow Up Time </th>
                    <th> Follow Up Remarks </th>
                    <th> Agent </th>
                    <th> Status </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {lead_followups &&
                    lead_followups.map((item, index) => {
                      return (
                        <tr>
                          <td> {index + 1} </td>
                          <td>{item.follow_up_date}</td>
                          <td>{item.follow_up_time}</td>
                          <td>{item.follow_up_remarks}</td>
                          <td>{item.agent}</td>
                          <td>{item.status}</td>
                          <td>
                            <Link>
                              <i className="fa fa-edit"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FollowUpComponent;
