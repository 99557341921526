import { combineReducers } from "redux";
import { alert } from "./alert_reducer";
import { client_reducer } from "./client_reducer";
import { authReducer } from "./auth_reducer";
import { centre_reducer } from "./centre_reducer";
import { product_reducer } from "./product_reducer";
import { processingtype_reducer } from "./processingtype_reducer";
import { leadsource_reducer } from "./leadsource_reducer";
import { leadfeedbacktype_reducer } from "./leadfeedbacktype_reducer";
import { followup_reducer } from "./followup_reducer";
import { agent_reducer } from "./agent_reducer";
import { lead_reducer } from "./lead_reducer";
import { status_reducer } from "./status_reducer";
import {leadstageageing_reducer} from "./leadstageageing_reducer"

export default combineReducers({
  alert,
  auth: authReducer,
  client: client_reducer,
  centre: centre_reducer,
  product: product_reducer,
  processingtype: processingtype_reducer,
  leadsource: leadsource_reducer,
  leadfeedbacktype: leadfeedbacktype_reducer,
  followup: followup_reducer,
  agent: agent_reducer,
  lead: lead_reducer,
  status: status_reducer,
  leadstageageing:leadstageageing_reducer,
});
