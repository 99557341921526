import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { upload } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/agents_enum";
import { useEffect } from "react";
import { useCreateAgent, useAllAgents } from "../../shared/hooks/UseAgent";
import { useSelectAllClient } from "../../shared/hooks/UseClient";
import { useSelectAllCentre } from "../../shared/hooks/UseCentre";
const AddAgent = ({}) => {
  let history = useHistory();
  const [addData] = useCreateAgent();
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [client] = useSelectAllClient();
  const [centre] = useSelectAllCentre();
  const [dropdownOptions, setDropdownOptions] = useState({});

  const submitFormClicked = async (values) => {
    await addData(values);
    history.push(`/${LINK_URL}`);
  };
  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.name, value: item.name };
      });

      setDropdownOptions({ ...dropdownOptions, client: newData });
    }
  }, [client]);
  useEffect(() => {
    if (centre && centre.all_centres) {
      const newData = centre.all_centres.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, centre: newData });
    }
  }, [centre]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAgent;
