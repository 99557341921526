import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Pagination from "../../components/common/Pagination";
import Filters from "../../components/common/Filters";
import AddBtn from "../../components/common/AddBtn";
import {
  SEARCH_TERMS,
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/followups_enum";
import DataTable from "../../components/common/DataTable";
import { useAllFollowups } from "../../shared/hooks/UseFollowup";
import { Link } from "react-router-dom";
const AllFollowups = ({}) => {
  const [
    data,
    pageNumber,
    setPageNumber,
    q,
    term,
    setTerm,
    termField,
    setTermField,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    deleteBtnClicked,
    resetFilter,
  ] = useAllFollowups();
  const { loading, followups, total_followups, page, pages } = data;

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink={PAGE_TITLE}
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
              >
                <div className="card-body">
                  <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />
                  {total_followups} records found
                  <hr />
                  <Filters
                    q={q}
                    resetFilter={resetFilter}
                    setTerm={setTerm}
                    term={term}
                    termField={termField}
                    setTermField={setTermField}
                    all_terms={SEARCH_TERMS}
                    dateFrom={dateFrom}
                    setDateFrom={setDateFrom}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                  />
                  <hr />
                  <div>
                    <table className="table align-middle  table-striped ">
                      <thead>
                        <tr className="bg-transparent" role="row">
                          <th>#</th>
                          <th> Customer </th>
                          <th> Phone </th>
                          <th> Follow Up Date </th>
                          <th> Follow Up Time </th>
                          <th> Follow Up Remarks </th>
                          <th> Agent </th>
                          <th> Status </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {followups &&
                          followups.map((item, index) => {
                            return (
                              <tr>
                                <td> {index + 1} </td>
                                <td>
                                  {item.lead && `${item.lead.customer_name} `}
                                </td>
                                <td>{item.lead && `${item.lead.phone} `}</td>
                                <td>{item.follow_up_date}</td>
                                <td>{item.follow_up_time}</td>
                                <td>{item.follow_up_remarks}</td>
                                <td>{item.agent}</td>
                                <td>{item.status}</td>
                                <td>
                                  {item.lead && (
                                    <Link
                                      to={`/leads/${item.lead._id}/view`}
                                      className="btn btn-soft-light"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                  )}

                                  <Link
                                    to={`/followups/${item._id}/edit`}
                                    className="btn btn-soft-light"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    {/* <DataTable
                      keys={view_all_table}
                      data={followups}
                      field={LINK_URL}
                      page={page}
                      deleteBtnClicked={deleteBtnClicked}
                      loading={loading}
                    /> */}
                    <Pagination
                      data={followups}
                      page={page}
                      pages={pages}
                      count={total_followups}
                      setPage={setPageNumber}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllFollowups;
