import moment from "moment";

export const PAGE_TITLE = "Followups";
export const PAGE_SINGLE_TITLE = "Followup";
export const LINK_URL = "followups";
export const SEARCH_TERMS = ["follow_up_date"];
export const inputFields = {
  lead: {
    type: "related",
    required: true,
    title: "Lead",
  },
  follow_up_date: {
    type: "string",
    required: false,
    title: "Follow Up Date",
    inputType: "date",
  },
  follow_up_time: {
    type: "string",
    required: false,
    title: "Follow Up Time",
    inputType: "time",
  },
  follow_up_remarks: {
    type: "string",
    required: false,
    title: "Follow Up Remarks",
  },
  status: {
    type: "select",
    required: false,
    title: "Status",
    options: [
      { label: "Pending", value: "Pending" },
      { label: "Called", value: "Called" },
      { label: "Not Picking", value: "Not Picking" },
      { label: "Denied", value: "Denied" },
    ],
  },
  agent: {
    type: "related",
    required: false,
    title: "Agents",
  },
};
export const initialValues = {
  lead: "",
  follow_up_date: "",
  follow_up_time: "",
  follow_up_remarks: "",
  agent: "",
};

export const view_all_table = [
  { name: "Lead", value: "lead" },
  { name: "Follow Up Date", value: "follow_up_date" },
  { name: "Follow Up Time", value: "follow_up_time" },
  { name: "Follow Up Remarks", value: "follow_up_remarks" },
  { name: "Agent", value: "agent" },
  { name: "Status", value: "status" },
];
