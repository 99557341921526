export const MENU = [
  {
    label: "Dashboard",
    link: "/dashboard",
  },

  {
    label: "Leads",
    link: "#",
    menu: [
      {
        label: "New Leads",
        link: "/leads",
      },
      {
        label: "All Leads",
        link: "/leads",
      },
    ],
  },
  {
    label: "Follow Ups",
    link: "#",
    menu: [
      {
        label: "Follow Ups",
        link: "/followups",
        menu: [
          { label: "View Follow Ups", link: "/followups" },
          { label: "Add Follow Up", link: "/followups/add" },
        ],
      },
    ],
  },
  {
    label: "Master",
    link: "#",
    admin: true,
    menu: [
      {
        label: "Employee",
        link: "#",
        menu: [
          {
            label: "Employees",
            link: "/agents",
          },
          {
            label: "Add Employee",
            link: "/agents/add",
          },
        ],
      },
      {
        label: "Lead Feedback Type",
        link: "#",
        menu: [
          {
            label: "View Lead Feedback Types",
            link: "/leadfeedbacktypes",
          },
          {
            label: "Add Lead Feedback Type",
            link: "/leadfeedbacktypes/add",
          },
        ],
      },
      {
        label: "Lead Sources",
        link: "#",
        menu: [
          {
            label: "View Lead Sources",
            link: "/leadsources",
          },
          {
            label: "Add Lead Source",
            link: "/leadsources/add",
          },
        ],
      },
      {
        label: "Processing Type",
        link: "#",
        menu: [
          {
            label: "View Processing Types",
            link: "/processingtypes",
          },
          {
            label: "Add Processing Type",
            link: "/processingtypes/add",
          },
        ],
      },
      {
        label: "Status",
        link: "#",
        menu: [
          {
            label: "View Status",
            link: "/status",
          },
          {
            label: "Add Status",
            link: "/status/add",
          },
        ],
      },
      {
        label: "Product",
        link: "#",
        menu: [
          {
            label: "View Products",
            link: "/products",
          },
          {
            label: "Add Product",
            link: "/products/add",
          },
        ],
      },
      {
        label: "Centre",
        link: "#",
        menu: [
          {
            label: "View Centres",
            link: "/centres",
          },
          {
            label: "Add Centre",
            link: "/centres/add",
          },
        ],
      },
      {
        label: "Clients",
        link: "#",
        menu: [
          {
            label: "View Clients",
            link: "/clients",
          },
          {
            label: "Add Client",
            link: "/clients/add",
          },
        ],
      },
    ],
  },
];
