export const PAGE_TITLE = "Leads";
export const PAGE_SINGLE_TITLE = "Lead";
export const LINK_URL = "leads";
export const SEARCH_TERMS = ["customer_name", "phone", "agent"];
export const inputFields = {
  customer_name: {
    type: "string",
    required: true,
    title: "Customer Name",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
  },
  alt_phone: {
    type: "string",
    required: false,
    title: "Alt Phone",
  },
  email: {
    type: "string",
    required: true,
    title: "Email",
  },
  product: {
    type: "related",
    required: true,
    title: "Product",
  },
  processing_type: {
    type: "related",
    required: true,
    title: "Processing Type",
  },
  status: {
    type: "related",
    required: false,
    title: "Status",
  },
  remarks: {
    type: "string",
    required: false,
    title: "remarks",
    inputType: "text",
  },
  agent: {
    type: "related",
    required: true,
    title: "Agent",
  },
  lead_feedback_type: {
    type: "related",
    required: false,
    title: "Lead Feedback Type",
  },
  lead_source: {
    type: "related",
    required: false,
    title: "Lead Source",
  },
  centre: {
    type: "related",
    required: true,
    title: "Centre",
  },
  client: {
    type: "related",
    required: true,
    title: "Client",
  },
};
export const initialValues = {
  customer_name: "",
  phone: "",
  alt_phone: "",
  email: "",
  product: "",
  processing_type: "",
  status: "",
  remarks: "",
  agent: "",
  lead_feedback_type: "",
  lead_source: "",
  centre: "",
  client: "",
};

export const view_all_table = [
  { name: "Customer Name", value: "customer_name" },
  { name: "Phone", value: "phone" },
  { name: "Product", value: "product" },
  { name: "Processing Type", value: "processing_type" },
  { name: "Centre", value: "centre" },
  { name: "Agent", value: "agent" },
  { name: "Client", value: "client" },
  { name: "Feedback Type", value: "lead_feedback_type" },
  { name: "Status", value: "status" },
];
