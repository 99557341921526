import {
  GET_AGENTS,
  ADD_AGENT,
  EDIT_AGENT,
  GET_AGENT,
  RESET_AGENT,
  GET_ALL_AGENTS,
  ERROR_AGENT,
} from "../types/agent_type";

const initialState = {
  agents: null,
  page: null,
  pages: null,
  agent: null,
  total_agents: 0,
  loading: true,
  error: {},
  agent_message: null,
  all_agents: null,
};

export const agent_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_AGENTS:
      return {
        ...state,
        agents: payload.users,
        pages: payload.pages,
        page: payload.page,
        total_agents: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_AGENTS:
      return {
        ...state,
        all_agents: payload,
        loading: false,
        error: {},
      };

    case RESET_AGENT:
      return {
        ...state,
        agent: null,
        loading: true,
        error: {},
      };

    case ADD_AGENT:
      return {
        ...state,
        agent_message: payload,
        loading: false,
        error: {},
      };
    case GET_AGENT:
      return {
        ...state,
        agent: payload,
        loading: false,
        error: {},
      };
    case EDIT_AGENT:
      return {
        ...state,
        agent_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_AGENT:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
