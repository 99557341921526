import api from "../../domain/api";
import {
  GET_AGENTS,
  ADD_AGENT,
  EDIT_AGENT,
  GET_AGENT,
  RESET_AGENT,
  GET_ALL_AGENTS,
  ERROR_AGENT,
} from "../types/agent_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addAgent = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/users/add`, formData);
    dispatch({
      type: ADD_AGENT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAgents =
  ({
    termField = "",
    term = "",
    pageNumber = "",
    start_from = "",
    start_to = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_AGENT,
      });
      const { data } = await api.get(
        `/users?searchTerm=${termField}&keyword=${term}&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}`
      );

      dispatch({
        type: GET_AGENTS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getAgent = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_AGENT,
    });
    const { data } = await api.get(`/users/${id}`);

    dispatch({
      type: GET_AGENT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editAgent = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/users/${id}`, formData);
    dispatch({
      type: EDIT_AGENT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteAgent = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/users/${id}`);
    dispatch(setAlert("Agent Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllAgents = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/users/all`);

    dispatch({
      type: GET_ALL_AGENTS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_AGENT });
};
